import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/css/styles.css';
import './styles/scss/app.scss';
import 'sweetalert2/src/sweetalert2.scss'
import {EventType, LogLevel, PublicClientApplication} from "@azure/msal-browser"
import App from './App';
import CssBaseline from "@mui/material/CssBaseline";
import {SettingsConsumer, SettingsProvider} from "./settings";
import {createTheme} from "./theme";
import {ThemeProvider} from "@mui/material";
import {RecoilRoot} from "recoil";
import 'simplebar-react/dist/simplebar.min.css';
import "nprogress/nprogress.css";
import {datadogRum} from '@datadog/browser-rum';
import packageJson from '../package.json';
import {MsalProvider} from '@azure/msal-react';

if (process.env.REACT_APP_ENABLE_DD === "ENABLE_DD") {
  datadogRum.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
    site: process.env.REACT_APP_DD_SITE,
    service: 'secretaria-digital-v2-prod',
    env: 'prod',
    version: packageJson.version || undefined,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: ["*"]
  })
  datadogRum.startSessionReplayRecording()
}

const pca = new PublicClientApplication({
  auth: {
    clientId: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
    authority: `${process.env.REACT_APP_OAUTH_AUTHORITY}`,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false,
  },
  system: {
    asyncPopups: false,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    }
  }
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    // @ts-ignore
    pca.setActiveAccount(event?.payload?.account);
  }
});

const rootElement = document.getElementById('root');

const IndexApp = () => {
  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  return (
    <React.StrictMode>
      <MsalProvider instance={pca}>
        <RecoilRoot>
          <SettingsProvider>
            <SettingsConsumer>
              {(settings) => {
                // Prevent theme flicker when restoring custom settings from browser storage
                if (!settings.isInitialized) {
                  // return null;
                }
                
                const theme = createTheme({
                  colorPreset: settings.colorPreset,
                  contrast: settings.contrast,
                  // @ts-ignore
                  direction: settings.direction,
                  paletteMode: settings.paletteMode,
                  responsiveFontSizes: settings.responsiveFontSizes
                });
                
                return (
                  <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <BrowserRouter basename={baseUrl || undefined}>
                      <App/>
                    </BrowserRouter>
                  </ThemeProvider>
                );
              }}
            </SettingsConsumer>
          </SettingsProvider>
        </RecoilRoot>
      </MsalProvider>
    </React.StrictMode>
  );
}

// pca.initialize().then(async () => {
//   await pca.clearCache()
  ReactDOM.render(<IndexApp/>, rootElement);
// })
